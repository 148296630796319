import classNames from 'classnames';
import Routes from 'core/routes';
import { Link } from 'gatsby';
import Logo from 'images/logo-black.png';
import React from 'react';

import css from './style.module.scss';

type NavigationProps = {
  fixed?: boolean
}

const Navigation: React.FC<NavigationProps> = ({ fixed }) => (
  <div className={classNames(css.container, { [css.fixed]: fixed })}>
    <div className={css.content}>
      <Link to={Routes.home}>
        <img className={css.image} src={Logo} alt="CobuildIt" />
      </Link>
    </div>
  </div>
)

Navigation.defaultProps = {
  fixed: false,
}

export default Navigation
