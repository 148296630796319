import './style.scss';

import React from 'react';

import css from './style.module.scss';

// import Footer from 'modules/footer';
type ApplicationProps = {
  children: React.ReactNode
}

const Application: React.FC<ApplicationProps> = ({ children }) => (
  <>
    <main className={css.container}>{children}</main>
    {/* <Footer /> */}
  </>
)

export default Application
