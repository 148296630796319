import React from 'react';

import css from './style.module.scss';

type TextType = "inline" | "block"

type TextProps = {
  className?: string
  spacingBottom?: boolean
  type?: TextType
  onClick?: (e: React.MouseEvent) => void
} & React.DetailedHTMLProps<React.HTMLAttributes<any>, any>

const Text: React.FC<TextProps> = ({
  children,
  className,
  spacingBottom,
  type,
  onClick,
  ...rest
}) => {
  let composedClassName = css.container
  if (!!className) composedClassName += ` ${className}`
  if (!!spacingBottom) composedClassName += ` ${css.spacingBottom}`

  if (type === "inline")
    return (
      <span className={composedClassName} onClick={onClick} {...rest}>
        {children}
      </span>
    )

  if (type === "block")
    return (
      <div className={composedClassName} onClick={onClick} {...rest}>
        {children}
      </div>
    )
}

Text.defaultProps = {
  type: "block",
}

export default Text
