import copy from 'clipboard-copy';

const generateSlugFromString = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaaeeeeiiiioooouuuunc------"

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

const getFullUrlFromSlug = (slug: string) => {
  return `https://blog.cobuildit.com/${slug}`
}

const copyToClipboard = async (content: string) => {
  return copy(content)
}

// const getContentPreview = (
//   content: string,
//   length: number,
//   ellipsis: boolean = true
// ) => {
//   if (!content || content === "") return ""

//   const temp = document.createElement("div")
//   temp.innerHTML = content
//   let result = temp.textContent

//   if (result.length > length) {
//     result = result.substring(0, Math.min(content.length, length))

//     if (ellipsis) result = result + "..."
//   }

//   return result
// }

export default {
  generateSlugFromString,
  getFullUrlFromSlug,
  copyToClipboard,
}
