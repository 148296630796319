import React from 'react';

import css from './style.module.scss';

type Heading4Props = {
  children: React.ReactNode
  className?: string
  light?: boolean
  bold?: boolean
}

const Heading4: React.FC<Heading4Props> = ({
  children,
  className,
  light,
  bold,
}) => {
  let composedClassName = ""
  if (!!className) composedClassName += ` ${className}`
  if (!!light) composedClassName += ` ${css.light}`
  if (!!bold) composedClassName += ` ${css.bold}`
  return <h4 className={composedClassName}>{children}</h4>
}

export default Heading4
