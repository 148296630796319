import Heading4 from 'modules/heading-4';
import Text from 'modules/text';
import React from 'react';
import { format } from 'timeago.js';

import css from './style.module.scss';

type TitleCardProps = {
  title?: string
  publishedAt?: string
  author?: BlogPostAuthorType
  customAuthor?: string
  color?: string
}

const TitleCard: React.FC<TitleCardProps> = ({
  title,
  publishedAt,
  customAuthor,
  author,
  color,
}) => {
  const relativeDate = format(publishedAt)
  const fullDate = new Date(publishedAt).toDateString()
  const computedAuthor = customAuthor ?? author.fullName ?? "CobuildIt"
  return (
    <>
      <Text title={fullDate} className={css.pretitle}>
        {relativeDate}
      </Text>
      <div className={css.titlecontainer}>
        <div className={css.color} style={{ backgroundColor: color }}></div>
        <Heading4 className={css.title}>{title}</Heading4>
      </div>
      <Text className={css.subtitle}>{computedAuthor}</Text>
    </>
  )
}

export default TitleCard
